.ml-5 {
  margin-left: 25px;
}
.ml-4 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 15px;
}
.ml-2 {
  margin-left: 10px;
}
.ml-1 {
  margin-left: 5px;
}
.mg-5 {
  margin: 5px 5px;
}

.mg-10 {
  margin: 10px 10px;
}

.mg-15 {
  margin: 15px 15px;
}

.pd-5 {
  padding: 5px 5px;
}

.pd-10 {
  padding: 10px 10px;
}

.pd-15 {
  padding: 15px 15px;
}

.mg-t-2 {
  margin-top: 2px;
}

.mg-t-3 {
  margin-top: 3px;
}

.mg-t-4 {
  margin-top: 4px;
}

.mg-t-5 {
  margin-top: 5px;
}

.mg-t-6 {
  margin-top: 6px;
}

.mg-t-10 {
  margin-top: 10px;
}
.mg-t-20 {
  margin-top: 20px;
}

.mg-t-70 {
  margin-top: 70px;
}

///
.mg-l-2 {
  margin-left: 2px;
}

.mg-l-3 {
  margin-left: 3px;
}

.mg-l-4 {
  margin-left: 4px;
}

.mg-l-5 {
  margin-left: 5px;
}

.mg-l-6 {
  margin-left: 6px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-l-15 {
  margin-left: 15px;
}

.mg-l-25 {
  margin-left: 25px;
}

.pd-l-2 {
  padding-left: 2px;
}

.pd-l-3 {
  padding-left: 3px;
}

.pd-l-4 {
  padding-left: 4px;
}

.pd-l-5 {
  padding-left: 5px;
}

.pd-l-6 {
  padding-left: 6px;
}

.pd-l-10 {
  padding-left: 10px;
}

////
.mg-r-2 {
  margin-right: 2px;
}

.mg-r-3 {
  margin-right: 3px;
}

.mg-r-4 {
  margin-right: 4px;
}

.mg-r-5 {
  margin-right: 5px;
}

.mg-r-6 {
  margin-right: 6px;
}

.mg-r-10 {
  margin-right: 10px;
}

////
.mg-b-2 {
  margin-bottom: 2px;
}

.mg-b-3 {
  margin-bottom: 3px;
}

.mg-b-4 {
  margin-bottom: 4px;
}

.mg-b-5 {
  margin-bottom: 5px;
}

.mg-b-6 {
  margin-bottom: 6px;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-t-20 {
  margin-bottom: 20px;
}
.task-job-daily-background {
  background-color: #e9cbc5;
}

.table-container-custom {
  .ant-table.ant-table-small {
    font-size: 11px;
    font-weight: 400;

    .ant-table-cell {
      line-break: auto;

      // height: 76px; // min height per row

      .trade {
        overflow-wrap: normal;
      }
      &:has(.order-choose) {
        padding: 0;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 4px 4px;
      .ant-tag {
        padding: 0 2px;
        margin-right: 2px;
      }
    }
  }
  .ant-table {
    border-radius: 12px;
  }
}

.table-container-custom-assign-history {
  .ant-table.ant-table-small {
    font-size: 12px;
    font-weight: 400;

    .ant-table-cell {
      line-break: auto;

      // height: 76px; // min height per row

      .trade {
        overflow-wrap: normal;
      }
      &:has(.order-choose) {
        padding: 0;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 6px 6px;
      .ant-tag {
        padding: 0 2px;
        margin-right: 2px;
      }
    }
  }
  .ant-table {
    border-radius: 12px;
  }
}
