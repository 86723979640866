.card-container > * {
  font-family: Arial, Helvetica, sans-serif !important;
}

.card-container {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #f9f9f9;
}

.card-image {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 1rem !important;
}

.card-paragraph {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* New New */
/* A4:  */
.content-boxed {
  display: flex;
  width: 210mm;
  height: 297mm;
  flex-direction: row;
}

.content-item {
  width: 50%;
  height: calc(100% / 3);
  background-color: red;
}

.bgc-item {
  background-color: blue;
}
