.list-notification:hover {
  background-color: #D1D9D9;
  cursor: pointer;
  border-radius: 5px;
}

.list-notification-opacity {
  // opacity: 0.8;
}

.test-notification-icon {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
}

.demo-infinite-container {
  height: 600px;
  padding: 8px 24px;
  overflow: auto;
  /* border: 1px solid #e8e8e8; */
  border-radius: 4px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
  [data-theme="dark"] .demo-infinite-container {
    border: 1px solid #303030;
  }