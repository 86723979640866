.ant-drawer-content .ant-drawer-wrapper-body {
  .ant-drawer-header {
    background-color: red;
    padding: 8px 16px;

    .ant-drawer-close {
      padding: 8px 16px;
    }
  }

  .ant-drawer-body {
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.google-marker {
  background-image: url('./../images/location.svg');
  width: 40px;
  height: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.form-input-data.ant-form {
  margin-bottom: 50px;

  .ant-row.submit-form-input-data {
    position: fixed;
    bottom: -25px;
    padding: 5px;
    width: 100%;
    background: white;
    border-top: 1px solid #f0f0f0;
    opacity: 0.8;

    .ant-btn {
      margin-left: 300px;
    }
  }
}

.steps-content {
  min-height: 200px;
  padding-top: 30px;
  margin-bottom: 30px;
}

.steps-action {
  margin-top: 24px;

  &.static-button {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    button {
      min-width: 10rem;
      max-width: 40%;
      border-radius: 1rem;
    }
  }
}

.text-align-right {
  text-align: 'right' !important;
}

.space-align-block .ant-space {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.red {
  color: red;
}
.yellow {
  color: #e48900;
}
.warning {
  color: orange;
}

.green {
  color: green;
}

.blue {
  color: blue;
}

.purple-white-board {
  color: purple;
}

.black {
  color: black;
}

.cont-number {
  color: blue;
}

.bg-red-pastel {
  background: #ff7878;
}
.wb-note {
  color: var(--wb-note);
}
.onp-note {
  color: var(--wb-onp-note);
}
.bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}

// Custom Form - Input
.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-input {
      border-radius: 5px;
    }
    .ant-picker {
      border-radius: 5px;
    }
    .ant-input-password {
      border-radius: 5px;
    }
    .ant-input-number-input {
      border-radius: 5px;
    }
    .ant-input-number {
      border-radius: 5px;
    }
  }
}
.scrollable-div {
  .btn-back-home-txt {
    display: none;
  }
  .btn-back-home {
    transition: width 0.5s linear;
  }
  &:hover {
    .btn-back-home-txt {
      display: inline;
    }
  }
}
// .scrollable-container {
//   .ant-btn-primary {
//     border-radius: 12px;
//   }
// }

// Custom Form - Select
.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-select-show-search {
      .ant-select-selector {
        border-radius: 5px;
      }
    }
  }
}

// .ant-select-selector:hover,
// .ant-select-selector:focus-within {
//   border: 1px solid blue !important;
// }
// custom hover option select
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  // border: 1px solid blue !important;
  // border-radius: 4px;
  // color: red;
  background-color: #86a7dc80 !important;
  font-weight: bold;
}
// Custom Input - search Text
.ant-input-group {
  .ant-input {
    border-radius: 5px;
  }
}

// .ant-table-cell {
//   .ant-input-affix-wrapper {
//     border-radius: 10px;
//   }
//   .ant-select-single {
//     .ant-select-selector {
//       border-radius: 25px;
//     }
//   }
// }
.tb-disable-select-all {
  .ant-table-thead .ant-table-selection-column .ant-table-selection {
    display: none;
  }
}
.table-wb-content {
  position: relative;
  width: 100%;

  .ant-table-body {
    // overflow-y: hidden !important;
    // &:hover {
    //   overflow-y: auto !important;
    // }
    overflow-y: auto !important;
    .ant-table-selection-column {
      width: 28px;
      min-width: 28px;
      padding: 4px !important;
    }
    .ant-table-selection-col {
      width: 28px !important;
      min-width: 28px !important;
      padding: 4px !important;
    }
  }
}

.ant-row {
  .ant-btn-primary {
    border-radius: 5px;
  }
}

.ant-modal-footer {
  .ant-btn-primary {
    border-radius: 5px;
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: 5px;
    .ant-modal-header {
      border-radius: 5px;
    }
    .ant-input-number {
      border-radius: 5px;
    }
  }
}
.position-fixed {
  position: fixed;
}
.z-index-100 {
  z-index: 100;
}
.tag-status {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0.8;
  box-shadow: 1px 1px 3px #888888;
  .ant-badge-status-text {
    color: black;
  }
}
.back-to-top-div {
  box-shadow: 1px 1px 3px #888888;
}
// .editable-cell {
//   position: relative;
// }

// .editable-cell-value-wrap {
//   padding: 5px 12px;
//   cursor: pointer;
// }

// .editable-row:hover .editable-cell-value-wrap {
//   padding: 4px 11px;
//   border: 1px solid #d9d9d9;
//   border-radius: 2px;
// }

// [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
//   border: 1px solid #434343;
// }

.recharts-legend-wrapper {
  bottom: -20px !important;
}
.text-align-center {
  text-align: center;
}

.border-timeline-drawer-left-car {
  border: 2px solid #316b83;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 2px;
}

.register-car {
  border: 1px solid blue;
  border-radius: 10px;
  width: 280px;
  margin: 50px 10px 10px 10px;
}

.register-romooc {
  border: 1px solid blue;
  border-radius: 10px;
  width: 280px;
  margin: 50px 10px 10px 10px;
}

.button-text-home:hover {
  background-color: #e0c097;
}

.order-from-ONP {
  color: black;
  background-color: #faf1e1;
}
.assign-support {
  color: black;
  background-color: #234a7e1e;
}

.order-from-WMS {
  color: black;
  background-color: #eef4ff;
}

.warning-date {
  text-transform: uppercase;
}
