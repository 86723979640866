.login-form {
  display: flex;
  height: 100vh;
  // background: #ececec;
  background-image: url('../images/Background-B-TMS.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  .login-content {
    margin: auto;
    margin-top: 100px;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    // box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }
}

.login-card {
  box-shadow: 0 10px 31px -10px #141618;
  background: #fff;
  border-radius: 12px;
  font-size: 13px;
  margin-bottom: 100%;
  display: inline-block;
}

.login-card:before {
  content: '';
  display: table;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 350px;
  height: 200px;
  background: url('../images/element-unilog.png') no-repeat;
}
