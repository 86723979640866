.button {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  transition: all ease 300ms;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  max-width: fit-content;
  cursor: pointer;
}

.button:hover {
  background-color: #e3e3e3;
}
