html:has(.wb) {
  overflow-y: hidden;
}
.bg-transit {
  background-color: #ffbcbc;
  border-radius: 5px;
  border-color: rgb(255, 187, 0);
  border-width: 3px;
  border-style: solid;
  // > td:first-child {
  //   border-top-left-radius: 15px;
  //   border-bottom-left-radius: 15px;
  // }
  // > td:last-child {
  //   border-top-right-radius: 15px;
  //   border-bottom-right-radius: 15px;
  // }
  &:active,
  &:focus {
    background-color: #c9d8b6;
  }
}

.bg-bill {
  background-color: #f5c6aa;
  border-radius: 5px;
  border-color: rgb(255, 187, 0);
  border-width: 3px;
  border-style: solid;
  // > tr {
  //   border: 5px solid red;
  // }

  // > td:first-child {
  //   border-top-left-radius: 15px;
  //   border-bottom-left-radius: 15px;
  // }
  // > td:last-child {
  //   border-top-right-radius: 15px;
  //   border-bottom-right-radius: 15px;
  // }
}

.bg-book {
  background-color: #93d9a3; //93D9A3
  border-radius: 5px;
  border-color: rgb(255, 187, 0);
  border-width: 10px;
  border-style: solid;
  // > td:first-child {
  //   border-top-left-radius: 15px;
  //   border-bottom-left-radius: 15px;
  // }
  // > td:last-child {
  //   border-top-right-radius: 15px;
  //   border-bottom-right-radius: 15px;
  // }
}

.color-CHS {
  color: #e48900;
}

// White-board
.color-YCLC {
  color: #562349;
}

.SI-Date {
  color: #763857;
}
.pointer {
  cursor: pointer;
}
.whiteboard-container {
  .ant-table.ant-table-small {
    font-size: 11px;
    // font-weight: 500;
    .ant-table-cell {
      line-break: auto;
      height: 76px; // min height per row

      .trade {
        overflow-wrap: normal;
      }
      &:has(.order-choose) {
        padding: 0;
      }
    }
    .ant-table-thead > tr > th {
      padding: 2px 2px;
    }
    .ant-table-tbody > tr > td {
      padding: 4px 4px;
      .ant-tag {
        padding: 0 2px;
        margin-right: 2px;
      }
    }
  }
  .ant-table {
    border-radius: 12px;
  }
}

.list-order-container {
  .ant-table.ant-table-small {
    font-size: 11px;
    // font-weight: 500;
  }
  .ant-table {
    // border-radius: 12px;
    margin-left: 15px;
    margin-bottom: 15px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
}

.table-wb-content {
  position: relative;
  width: 100%;

  .ant-table-body {
    // overflow-y: hidden !important;
    // &:hover {
    //   overflow-y: auto !important;
    // }
    overflow-y: auto !important;
    .ant-table-selection-column {
      width: 28px;
      min-width: 28px;
      padding: 4px !important;
    }
    .ant-table-selection-col {
      width: 28px !important;
      min-width: 28px !important;
      padding: 4px !important;
    }
  }
  .ant-table-tbody {
    .ant-btn.ant-btn-link {
      font-size: 10px;
      height: auto;
      // font-weight: 600;
      padding: 0 0;
      display: block;
      text-transform: uppercase;
    }
    .row-assigned {
      background-color: var(--wb-assigned);
      font-weight: 600;
      font-size: 10px;
    }
    .row-cancelled {
      background-color: var(--wb-row-cancelled);
    }
    & > tr.ant-table-row-level-0:hover > td {
      // background-color: var(--wb-hover);
      background: unset;
    }
    .Booking {
      .sub-no-number-txt {
        color: var(--wb-booking-txt);
      }
    }
    //--wb-bill-txt
    .Bill {
      .sub-no-number-txt {
        color: var(--wb-bill-txt);
      }
    }
    .wb-row-cm-warning {
      // font-weight: bolder;
      color: #e48900;
      &.lower-12-hours {
        color: red;
      }
    }
    .booking-wb-background {
      background-color: white;
    }
    .bill-wb-background {
      background-color: rgba(189, 229, 210, 0.397);
    }
  }
}

.rommooc-white-board-custom {
  border: 1px solid blue;
  padding: 0px;
  border-radius: 4px;
  margin: 2px 10px 2px 2px;
  padding: 0;
}
